
import {defineComponent,reactive,toRefs,ref,onMounted } from "vue";
import { useStore } from "vuex";
import formatDate from './../util/formatDate';
import {devCheckFind,devCheckAdd,getModelList,devCheckEdit,isEditAuthByCode} from "@/api/index";
import { ElMessage } from 'element-plus';

interface deviceDataF{
    loading: boolean,
    addDecreaseNumber:any,
    
  pageSize: number,
  pageSizes: Array<number>,
  currentPage: number,
  total: number,
  editForm: any
  
  editFlag: any
  modelOption: Array<any>,
  rowsData: Array<any>
}
export default defineComponent({
    name:'DevCheckTable',
   
    setup(){

        const type3 = ref();
        
        const deviceData:deviceDataF = reactive({
            loading: true,
            surplusNumber: 0,
            useCount: 0,
            addDecreaseNumber:'',
            
            editForm: {},
            editFlag: false,
            rowsData: [],
            modelOption:[],
            pageSize: 10,
            pageSizes: [10, 30, 50, 100],
            currentPage: 1,
            total: 0,
        });
        const data = toRefs(deviceData);

        const formatDateF = formatDate.formatDateTime;

        const getDevCheckFind = async()=>{
        
            await devCheckFind({length:deviceData.pageSize,page:deviceData.currentPage}).then((res:any)=>{
                deviceData.loading = false;
                if(res.data!=null && res.data.list!=null){
                  deviceData.rowsData = res.data.list
                  deviceData.total = res.data.total
              }else{
                  deviceData.rowsData = [];
                  deviceData.total = 0;
                  ElMessage.success('加载成功，数据为空！');
              }
            
            });
        }

        
        const add = async()=>{
        
        await devCheckEdit({id:deviceData.editForm.id,type:1,number:deviceData.addDecreaseNumber}).then((res:any)=>{
            ElMessage.closeAll();
            if (res.code == 0) {
                ElMessage.info("增加成功!");
                deviceData.editForm.useCount = res.data.count;
                deviceData.editForm.surplusNumber = res.data.number-res.data.count;
            } else {
                ElMessage.info("增加失败!");
            }
        
        });
        }

        
        const decrease = async()=>{
        
        await devCheckEdit({id:deviceData.editForm.id,type:0,number:deviceData.addDecreaseNumber}).then((res:any)=>{
            ElMessage.closeAll();
            if (res.code == 0) {
                ElMessage.info("减少成功!");
                deviceData.editForm.useCount = res.data.count;
                deviceData.editForm.surplusNumber = res.data.number-res.data.count;
            } else {
                ElMessage.info("减少失败!");
            }
        
        });
    }

    const editorFun = (obj: any) => {
        deviceData.editForm = obj;
      deviceData.editFlag = true;
      deviceData.editForm.isEdit = true;
      
      deviceData.editForm.useCount = obj.count;
      deviceData.editForm.surplusNumber = obj.number-obj.count;

      console.log("deviceData.editForm: ",deviceData.editForm);
    }
    
    const modelIdToNameFun = (id: any) => {
      for(let model of deviceData.modelOption){
        if(model.value == id){
            return model.label;
        }
      }
      return '';
    }

    const surplusNumberFun = (obj:any) => {
        return obj.number-obj.count;
    }

    const handleSizeChange = (val: any) => {
      //设置当前页显示几条数据
      deviceData.pageSize = val
      getDevCheckFind()
    }
    const handleCurrentChange = (val: any) => {
      //当前页码
      deviceData.currentPage = val
      getDevCheckFind()
    }

    const addFun = () => {
      deviceData.editForm = {number:500,count:0};
      deviceData.editForm.isEdit = false;
      deviceData.editFlag = true
      getDevCheckFind()
    }
    
    const editSave =async () => {
            
        await devCheckAdd(deviceData.editForm).then((res:any)=>{
            ElMessage.closeAll();
            if (res.code == 0) {
                ElMessage.info("增加成功!");
                deviceData.editFlag = false;
                getDevCheckFind();
            } else {
                ElMessage.info("增加失败!");
            }
        
        });

    }

        onMounted(async()=>{
            
        await getModelList({isPage:false}).then((res:any)=>{
            ElMessage.closeAll();
            if (res.code == 0) {
                // ElMessage.info("减少成功!");
                for (let o of res.data.list) {
                    deviceData.modelOption.push({
                    value: o.id,
                    label: o.name
                });
                }
            } else {
                // ElMessage.info("减少失败!");
            }
        
        });
            getDevCheckFind();
        });
        return {
            ...data,
            formatDateF,
            getDevCheckFind,
            isEditAuthByCode,editSave,surplusNumberFun,
            add,decrease,handleCurrentChange,handleSizeChange,editorFun,modelIdToNameFun,addFun
        }
    }
})
